<template>
    <modal ref="modalGirarRuleta" :titulo="`Ruleta del dia #${countDays}`" no-aceptar no-cancelar tamano="modal-lg">
        <div class="row mx-0 justify-center">
            <div class="col-9 text-title-wheel text-dk-blue f-600 text-center f-25 lh-21">
                Gira la ruleta y gana <br /> fabulosas recompensas
            </div>
            <div v-if="!fnSpinn" class="col-9 mt-4 text-center">
                <div class="row mx-0 justify-center mb-4">
                    <div class="wheel-outside p-3">
                        <div class="spinner-background" />
                        <div class="spinner-wheel p-4">
                            <div v-for="(sp , s) in premios" :key="s" :class="`space-wheel-${sp.codigo}`">
                                <p v-if="sp.cant" class="text-center text-magenta f-13 f-500">
                                    {{ sp.cant }}
                                </p>
                                <img :src="imgReward(sp.tipo, sp.url)" width="28" height="28" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-spinn-button row mx-0 mt-2 justify-center">
                    <div class="btn-gr-purple font-omnes cr-pointer text-white px-5" @click="girar">
                        Girar
                    </div>
                </div>
            </div>
            <claim-reward v-show="fnSpinn" :reward-type="4" url-reward="https://falabella.scene7.com/is/image/FalabellaCO/19990935_1?wid=800&hei=800&qlt=70" class="col-9 component-reward" />
        </div>
    </modal>
</template>

<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.10.4/gsap.min.js"></script> -->
<script>
import gsap from "gsap";

export default {
    components: {
        claimReward: () => import('../components/claimReward')
    },
    props: {
        countDays: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            fnSpinn: false,

            premios: [
                {   
                    id: 1,
                    codigo: '01',
                    tipo: 1,
                    cant: '$ 20.000'
                },
                {
                    id: 2,
                    codigo: '02',
                    tipo: 2,
                },
                {
                    id: 3,
                    codigo: '03',
                    tipo: 3,
                    cant: '+ 50'
                },
                {
                    id: 4,
                    codigo: '04',
                    tipo: 4,
                    cant: '+ 5'
                },
                {
                    id: 5,
                    codigo: '05',
                    tipo: 5,
                    url: 'https://falabella.scene7.com/is/image/FalabellaCO/11591020_1?wid=800&hei=800&qlt=70'
                },
                {
                    id: 6,
                    codigo: '06',
                    tipo: 2,
                },
                {
                    id: 7,
                    codigo: '07',
                    tipo: 2,
                },
                {
                    id: 8,
                    codigo: '08',
                    tipo: 2,
                },
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalGirarRuleta.toggle();
        },
        girar(){
            let randomNum = 0;
            randomNum += (Math.random() * 360) + 2880;
            console.log(randomNum)

            let animation = gsap.timeline({});
            animation.to(".row-spinn-button", {duration: .5, opacity:0.0 });
            animation.to(".spinner-wheel", {rotation: randomNum, duration: 5, ease: "elastic"}); // Check ease animation, maybe change for a strong animation
            animation.to(".wheel-outside", {opacity: 0.0, duration: .8, ease: 'power', onComplete: () => {
                this.fnSpinn = !this.fnSpinn; 
            }});
            animation.to(".text-title-wheel", {opacity: 0.0, duration: .8, ease: 'power'});
            animation.fromTo(".component-reward",{ scale: 0.0, opacity: 0 }, { opacity: 1 , scale: 1, duration: 2, ease: 'power1' });
        },
        imgReward(state, rewardUrl){
            switch (state){
            case 1:
                return '/img/gaming/i_cupon.png';    
            case 2:
                return '/img/gaming/i_triste.png';
            case 3:
                return '/img/gaming/i_moneda.svg';
            case 4:
                return '/img/gaming/i_gema.svg';
            case 5:
                return rewardUrl;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-gr-purple{
    height: 44px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    background-image: linear-gradient(to bottom, #F9C7FF, #9F4BFF, #612AD5);
}

.wheel-outside{
    width: 328px;
    height: 328px;
    border-radius: 50%;
    
    position: relative;
    .spinner-background{
        position: absolute;
        background-position: center;
        z-index: 2;
        background-image: url('/img/gaming/ruleta_marco.svg');
        width: 288px;
        height: 288px;
        z-index: 1;
        border-radius: 50%;
        top: 6%;
        left: 6%;
    }
    .spinner-wheel{
        position: absolute;
        width: 288px;
        height: 288px;
        z-index: 0;
        border-radius: 50%;
        top: 6%;
        left: 6%;
        background-position: center;
        background-image: url('/img/gaming/ruleta_fondo.png');
        .space-wheel{
            width: 80px;
            &-01{
                @extend .space-wheel;
                transform: rotate(312deg)translate(-12px, 37px);
            }
            &-02{
                @extend .space-wheel;
                transform: rotate(1deg)translate(79px, -29px);
            }
            &-03{
                @extend .space-wheel;
                transform: rotate(48deg)translate(60px, -134px);
            }
            &-04{
                @extend .space-wheel;
                transform: rotate(91deg)translate(-30px, -157px);
            }
            &-05{
                @extend .space-wheel;
                transform: rotate(135deg)translate(-103px , -98px);
            }
            &-06{
                @extend .space-wheel;
                transform: rotate(180deg)translate(-81px , 13px);
            }
            &-07{
                @extend .space-wheel;
                transform: rotate(225deg)translate(27px , 58px);
            }
            &-08{
                @extend .space-wheel;
                transform: rotate(267deg)translate(149px , 3px);
            }
        }
    }
}

.text-magenta{
    color: #A02A78;
}
</style>